import React from "react";

import PageWrapper from "../../components/PageWrapper";
import Progress from "../../sections/home2/Progress";
import Content1 from "../../sections/home2/Content1";
import Content2 from "../../sections/home2/Content2";
import { Link } from 'gatsby';

import SEO from '../../components/SEO';

const Home2 = ({location}) => {
  return (
    <>
      <PageWrapper        
        themeConfig={{
          headerClassName: "site-header--menu-left",
          headerFluid: false,

          footerStyle: "digma",
        }}>
        <SEO
          title="Digma's in-depth Analytics - Track users views, clicks and more"
          description="Keep track of how your videos are performing with our in-depth analytics"
        />            
        <div className="container pt-20 pt-md-16 pt-lg-20 ">
          <nav className="breadcrumb" aria-label="Breadcrumb">
            <ol className="breadcrumb__list">
            <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/">Home</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info/">Info</Link>
                <span className="breadcrumb__separator" aria-hidden="true"> / </span>
              </li>              
              <li className="breadcrumb__item">
                <Link className="breadcrumb__link" to="/info/analytics">Analytics</Link>
              </li>
              </ol>
           </nav>
        </div>        
        <Progress className="pb-lg-21" />
        <Content1 className="pb-10 pb-sm-5 pb-lg-14" />
        <Content2 className="pb-0 pb-lg-31" />
      </PageWrapper>
    </>
  );
};
export default Home2;
